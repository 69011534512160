import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// components
import LoadingScreen from '../components/LoadingScreen';
import RoleBasedGuard from '../guards/RoleBasedGuard';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        { 
          path: 'reset-password', 
          element: (
            <GuestGuard>
              <ResetPassword />
            </GuestGuard>
          ),
        },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <RoleBasedGuard roles={['admin']} hasContent>
            <DashboardLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        { path: 'users', element: <AllUsers /> },
        {
          path: 'posts',
          children: [
            { path: 'all-posts', element: <Posts /> },
            { path: 'create-post', element: <CreatePost /> },
            { path: 'edit-post', element: <EditPost /> },
          ],
        },
        {
          path: 'events',
          children: [
            { path: 'all-events', element: <Events /> },
            { path: 'create-event', element: <CreateEvent /> },
            { path: 'edit-event', element: <EditEvent /> },
          ],
        },
        {
          path: 'app-settings',
          children: [
            { path: 'call-life-line', element: <CallLifeLineButton /> },
            { path: 'help-a-mate', element: <HelpMateScreen /> },
            { path: 'help-myself', element: <HelpMyselfScreen /> },
            { path: 'donate', element: <DonateScreen /> },
          ],
        },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
    {
      path: '/',
      element: <Navigate to="/dashboard" replace />,
    },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));

// Dashboard
const AllUsers = Loadable(lazy(() => import('../pages/AllUsers')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));

// App content
const Posts = Loadable(lazy(() => import('../pages/Posts')));
const Events = Loadable(lazy(() => import('../pages/Events')));
const CreatePost = Loadable(lazy(() => import('../pages/CreatePost')));
const EditPost = Loadable(lazy(() => import('../pages/EditPost')));
const EditEvent = Loadable(lazy(() => import('../pages/EditEvent')));
const CreateEvent = Loadable(lazy(() => import('../pages/CreateEvent')));

// App settings
const CallLifeLineButton = Loadable(lazy(() => import('../pages/CallLifeLineButton')));
const HelpMateScreen = Loadable(lazy(() => import('../pages/HelpMateScreen')));
const HelpMyselfScreen = Loadable(lazy(() => import('../pages/HelpMyselfScreen')));
const DonateScreen = Loadable(lazy(() => import('../pages/DonateScreen')));