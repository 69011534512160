// theme
import palette from '../theme/palette';

// ----------------------------------------------------------------------

export const colorPresets = [
  // DEFAULT
  {
    name: 'default',
    ...palette.light.primary,
  },
  // BLUE
  {
    name: 'blue',
    lighter: '#CFF2F7',
    light: '#6CB8D1',
    main: '#144968',
    dark: '#0A2B4A',
    darker: '#031531',
    contrastText: '#fff',
  },
];

export const defaultPreset = colorPresets[0];
export const bluePreset = colorPresets[1];

export default function getColorPresets(presetsKey) {
  return {
    blue: bluePreset,
    default: defaultPreset,
  }[presetsKey];
}
