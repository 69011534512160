// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
};

const navConfig = [

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      {
        title: 'users',
        path: '/dashboard/users',
        icon: ICONS.user,
      },
    ],
  },


  // App Content
  // ----------------------------------------------------------------------
  {
    subheader: 'app content',
    items: [
      {
        title: 'Posts',
        path: '/dashboard/posts',
        icon: ICONS.blog,
        children: [
          { title: 'All Posts', path: '/dashboard/posts/all-posts' },
          { title: 'Create Post', path: '/dashboard/posts/create-post' },
        ],
      },
      {
        title: 'Events',
        path: '/dashboard/events',
        icon: ICONS.calendar,
        children: [
          { title: 'All Events', path: '/dashboard/events/all-events' },
          { title: 'Create Event', path: '/dashboard/events/create-event' },
        ],
      },
      {
        title: 'Dynamic Screens',
        path: '/dashboard/app-settings',
        icon: ICONS.menuItem,
        children: [
          { title: 'Call Life-Line Button', path: '/dashboard/app-settings/call-life-line' },
          { title: 'Help A Mate Screen', path: '/dashboard/app-settings/help-a-mate' },
          { title: 'Help Myself Screen', path: '/dashboard/app-settings/help-myself'  },
          { title: 'Donate Screen', path: '/dashboard/app-settings/donate' },
        
        ],
      },
     ],
  },
];

export default navConfig;
